import { useHistory } from "react-router-dom";
import { FetchTypes } from "../../toolympus/api/core";
import { useFetch } from "../../api/useFetch";
import { emptyTutor } from "../CourseManagement/emptyObjects";
import { Tutor } from "../CourseManagement/types";

export const useTutorEdit = (id: string) => {
    const history = useHistory();

    const {error, data, loading, reload} = useFetch<Tutor>(emptyTutor(), {url: '/admin/tutor/' + id});
    const {request, loading: actionLoading} = useFetch<null>(null, {url: '/admin/tutor/' + id}, false);

    const isNew = !!error;

    return {
        save: (body: Tutor) => {
            if(isNew) {
                request({body, method: FetchTypes.POST, url: '/admin/tutor'})
                    .then(() => history.push('/tutor/' + body._id));
            } else {
                request({body, method: FetchTypes.PUT})
                    .then(reload);
            }
        },
        remove: () => {
            request({method: FetchTypes.DELETE})
                .then(() => history.push('/tutors'))
        },
        isNew,
        data, 
        loading: loading || actionLoading,
    }
}
