import React from 'react'
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator'

import { Button } from '../Button'
import { AvatarContainer, AvatarHint, HorizontalSpace, Image } from './Avatar.style'
import { AvatarApi } from './types'

interface Props {
    avatar: AvatarApi;
    noChange?: boolean
}

export const Avatar = ({avatar: {loading, deletePhoto, upload, userId}, noChange}: Props) => {
    const openFileUploader = () => {
        const input = document.createElement('input') as HTMLInputElement;

        input.setAttribute('type', 'file');
        input.onchange = () => upload((input.files as FileList)[0]);
        input.click();
    }

    return <AvatarContainer className="avatar">
        <Image> {loading? <LoadingIndicator/> : <img src={'/api/avatar/' + (userId ||  "default")} alt="avatar"/>}  </Image>
        {noChange || <>
            <Button onClick={openFileUploader} color="primary" variant="outlined">Upload photo</Button>
            <HorizontalSpace />
            <Button onClick={deletePhoto} color="secondary" variant="outlined">Delete</Button>
            <AvatarHint>The uploaded image must be 500px wide and 500px long</AvatarHint>
        </>}
    </AvatarContainer>
}