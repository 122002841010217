import React, {useEffect, useRef, useState} from "react";
import {LoadingMessage, LoadingPanel, PageContainer, Separator, Time, CourseTitle, ZoomWindow, Hint, } from "./LectureRoomPage.style";
import {LoadingIndicator} from "../../toolympus/components/primitives/LoadingIndicator";
import {TutorInfo, TutorName} from "../AboutCourse/presentation/AboutCoursePage.style";
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import VideocamIcon from '@mui/icons-material/Videocam';
import moment from "moment";
import {Button} from "../common/Button";
import { Jutsu } from 'react-jutsu'
import { useTheme } from '@mui/material';
import { ZoomData } from "./types";
import { Schedule } from "../CourseManagement/types";
import { participantName, tutorPosition } from "../Participants/presentation/display";
import { useUser } from "../../toolympus/userContext/UserContext";

const TB_FOR_MODERATOR = [
    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
    'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
    'livestreaming', 'sharedvideo', 'settings', 'raisehand',
    'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
    'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone'
];


const TB_FOR_NO_MODERATOR = [
    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
    'fodeviceselection', 'hangup', 'chat',
    'sharedvideo', 'settings', 'raisehand',
    'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
    'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone'
];

interface Props {
    jitsi: {
        data: ZoomData | null, 
        loading: boolean
    },
    subject: {
        schedule?: Schedule,
        loading: boolean
    }
}

const useJitsiScript = (jitsiDomain?: string) => {
    useEffect(() => {
        if(jitsiDomain) {
            const script = document.createElement('script');
          
            script.src = `https://${jitsiDomain}/external_api.js`;
          
            document.body.appendChild(script);
          
            return () => {
              document.body.removeChild(script);
            }
        }
      }, [jitsiDomain]);
}

export const LectureRoomPage = ({jitsi, subject}: Props) => {
    const [openRoom, setOpenRoom] = useState<string | null>(null);
    const pageRef = useRef<HTMLElement>(null);
    const theme = useTheme();

    useJitsiScript(jitsi.data?.available ? jitsi.data.domain : undefined);
    const { user } = useUser();

    const hasAccessToPrivateRoom = (user?.roles || []).includes("tutor") || (user?.roles || []).includes("organizer") || (user?.roles || []).includes("admin");

    if (subject.loading || jitsi.loading || !jitsi.data) {
        return <LoadingIndicator />
    }

    const { tutors, start_datetime, end_datetime} = subject.schedule || { };

    const {room, name, available, token, domain, moderator} = jitsi.data;
    
    const TOOLBAR_BUTTONS = moderator? TB_FOR_MODERATOR : TB_FOR_NO_MODERATOR;


    const openWindow = (roomKind: "normal" | "private" = "normal") => {
      setOpenRoom(roomKind === "private" ? `[private] ${room}` : room);
      const page = (pageRef.current as HTMLElement).parentElement as HTMLElement;

      let count = 0;

      const cardHeight = page.scrollHeight / 15;

      const loopAnimation = () => {
          count++;
          page.scrollTo(0, count * cardHeight);

          if (count < 15) {
              setTimeout(loopAnimation, 20);
          }
      }

      loopAnimation();
    }

    const nButtons = 1 + (subject?.schedule?.external_link ? 2 : 0) + (hasAccessToPrivateRoom ? 1 : 0);

    return <PageContainer ref={pageRef as any}>
        {subject.schedule && <>
            <CourseTitle to={`/public/courses/${subject.schedule.course_id}`}>{subject.schedule.course?.title || ""}</CourseTitle>
            {(tutors || []).map(t => (
              <TutorInfo key={t._id}><TutorName>{participantName(t)},</TutorName> {tutorPosition(t)}</TutorInfo>
            ))}
            <Separator />
            <Time>
                <WatchLaterIcon color="disabled" />
                {moment(start_datetime).format("dddd, D MMMM: H:mm")} – {moment(end_datetime).format("H:mm")}
            </Time>
        </>}

        {available &&
            <ZoomWindow open={!!openRoom}>
              <div className="buttons">
                {subject.schedule?.external_link &&
                  <Hint>This lecture takes place outside the platform</Hint>}
                {subject.schedule?.external_link &&
                  <Button
                    href={subject.schedule?.external_link}
                    target="_blank"
                    rel="noreferrer noopener"
                    variant="contained"
                    color="primary"
                    >
                      Join the lecture (external)
                  </Button>}

                <Button 
                    onClick={() => openWindow()}
                    variant={subject.schedule?.external_link ? "text" : "contained"}
                    color="primary" 
                    endIcon={<VideocamIcon/>}
                    >
                    {subject.schedule?.external_link ? "Join the lecture room" : "Join the lecture"}
                </Button>

                {hasAccessToPrivateRoom &&
                  <Button 
                    onClick={() => openWindow("private")}
                    color="primary" 
                    endIcon={<VideocamIcon/>}
                    >
                    Join the private room
                </Button>}
              </div>
                
                <LoadingPanel open={!!openRoom}>
                    <LoadingMessage>The lecture room is loading...</LoadingMessage>
                    <LoadingIndicator />
                </LoadingPanel>
                
                {!!openRoom && 
                    <Jutsu
                        loadingComponent={<>The lecture room is loading...</>}
                        containerStyles={{
                            width: '100%',
                            height: '100%',
                            border: '3px solid ' + theme.palette.primary.main,
                            position: 'absolute',
                        }}

                        roomName={openRoom}
                        displayName={name}
                        domain={domain}
                        jwt={token}
                        onMeetingEnd={() => setOpenRoom(null)}
                        interfaceConfigOverwrite={{
                            HIDE_INVITE_MORE_HEADER: true,
                            TOOLBAR_BUTTONS
                        }}
                    />
                }
                
                <Hint offset={nButtons}>If you face issues joining the lecture, please try disabling VPN if you use one.</Hint>
                <Hint>If that doesn't help try using the latest versions of Chrome web-browser.</Hint>
                <Hint>If you need to join from outside the app, you may use <a href={`https://${domain}/${room}?jwt=${token}`} target="_blank" rel="noopener noreferrer">this link</a></Hint>
            </ZoomWindow>
        }
    </PageContainer>
}
