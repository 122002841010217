import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from "react";
import { LoadingIndicator } from '../../toolympus/components/primitives';
import { FormGrid, FormTitle } from '../../toolympus/components/primitives/Forms';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { FieldType, Schema } from '../../toolympus/hooks/useSchema';
import { media } from '../frame';
import { useSettingsEdit } from './useSettingsEdit';

export type LectureFieldProps = {
    token?: string,
    token_secret: string,
    available: boolean,
    domain: string,
    room: string
}

const Page = styled.div`
   margin-top: 2vw;
   width: 70%;

    ${media.phone} {
        width: 100%;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black;
`;

const LectureRoom = styled(FormGrid)`
    margin: 10px 0;
    align-items: center;
    font-size: 1rem;

    ${media.phone} {
        grid-template-columns: 1fr;
    }
`;

const SaveButton = styled(Button)`
    width: 140px;
    height: 36px;

    ${media.phone} {
        width: 30%;
    }
`;

const SettingsSchema: Schema = {
  is_applications_enabled: { label: "Applications are enabled", type: FieldType.bool },
  is_certificates_available: { label: "Certificates available", type: FieldType.bool },
};

export const SettingsPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        isLoading,
        lectureRoom,
        settings,
        isShowSaveButton,
    } = useSettingsEdit();

    const schemaInstanceForForm: Schema = {
        token_secret: { label: "Jitsi token", type: FieldType.text },
        available: { label: "Lecture room available", type: FieldType.bool },
        room: { label: "Lecture room name", type: FieldType.text },
        domain: { label: "Jitsi domain", type: FieldType.text },
    }

    return <Wrapper>
        <Title>
            <FormTitle>
                Settings
            </FormTitle>

            {
                isShowSaveButton && <SaveButton variant="contained" onClick={() => {
                    lectureRoom.save();
                    settings.save();
                    enqueueSnackbar("Saved", { variant: 'success', autoHideDuration: 3000 });
                }} style={{ backgroundColor: "#6184AD", color: "white" }}>
                    Save
                </SaveButton>
            }
        </Title>

        <Page>
            {
                !isLoading && <LectureRoom columns='2fr 2fr'>
                    <FormControlsForFields
                        data={lectureRoom.data}
                        schema={schemaInstanceForForm}
                        fields={[
                            ["token_secret"],
                            ["available"],
                            ["domain"],
                            ["room"]
                        ]}
                        onChange={(_, changes) => {
                            lectureRoom.update(changes);
                        }} />
                </LectureRoom>
            }

            { isLoading && <LoadingIndicator /> }
            <FormControlsForFields
                data={settings.data}
                fields={[
                  ["is_applications_enabled"],
                  ["is_certificates_available"],
                ]}
                schema={SettingsSchema}
                onChange={(_, changes) => {
                    settings.update(changes);
                }}
            />
        </Page>
    </Wrapper>
}