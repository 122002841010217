import { InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator'
import { Avatar } from '../../common/Avatar/Avatar'
import { useAvatar } from '../../common/Avatar/useAvatar'
import { Button } from '../../common/Button'
import { FormControl } from '../../common/TextField'
import { useForm } from '../../common/useForm'
import { Tutor, User } from '../types'
import { Block, FormContainer, PageContainer } from './TutorEditPage.style'
import { ActionRow, FormGrid, OccupyFreeSpace } from '../../../toolympus/components/primitives'

interface Props {
    data: {
        data: Tutor;
        loading: boolean;
        save: (body: Tutor) => void;
        remove: () => void;
        isNew: boolean;
    },
    users: User[];
    id: string;
}

export const TutorEditPage = ({ data: { data, loading, save, remove, isNew }, users, id }: Props) => {
    const avatar = useAvatar(data._id, '/admin/avatar/');
    const history = useHistory();
    const { input, autocomplete, valid, state } = useForm(data, { required: ["title", "firstname", "lastname", "company", "position"] });

    const user = users.filter(({_id}) => _id === id)[0];

    const onSave = () => {
        if (user) {
            save({
                ...state,
                _id: user._id,
                email: user.email
            });
        }
    }

    return (
    <PageContainer>
      <ActionRow firstItemNoMargin lastItemMarginRight="0" itemMarginTop="0">
        {loading && <LoadingIndicator /> }

        <OccupyFreeSpace />

        {!isNew && <Button noFullWidth onClick={() => remove()} variant="outlined" color="secondary">Delete</Button>}
        <Button noFullWidth disabled={!valid} onClick={onSave} variant="contained" color="primary">Save</Button>
      </ActionRow>

        <FormGrid columns="1fr 2fr">
          <Avatar avatar={avatar} />

          <FormContainer>
              <Block col={5}>
                <FormControl variant="filled">
                    <InputLabel>User*</InputLabel>
                    <Select
                        value={user?._id || ""}
                        onChange={({target: {value}}) => history.push('/tutor/' + value)}
                        disabled={!isNew}
                    >
                        {users.map(({_id, email}) => <MenuItem key={_id} value={_id}>{email}</MenuItem>)}
                    </Select>
                </FormControl>
              </Block>

              <Block col={1}>
                  {autocomplete("title", ["Mr", "Ms", "Dr", "Prof"])}
              </Block>
              <Block col="2">
                  {input('firstname')}
              </Block>
              <Block col="2">
                  {input('lastname')}
              </Block>
              <Block col="3">
                  {input('position')}
              </Block>
              <Block col="2">
                  {input('company', { label: "Organisation*" })}
              </Block>
              <Block col="5">
                  {input('bio', { label: <>Bio <i>(optionally)</i></>, rows: 3 })}
              </Block>
              <Block col="5">
                  {input('interests', { label: <>Interestes <i>(optionally)</i></> })}
              </Block>
          </FormContainer>
        </FormGrid>
        
    </PageContainer>)
}