import React from "react";
import { LoadingIndicator } from "../../../toolympus/components/primitives/LoadingIndicator";
import { Button } from "../../common/Button";
import { ProfileData } from "../types";
import { ColumnsContainer, FormContainer, Block } from "./MyProfilePage.style";
import { useForm } from "../../common/useForm";
import { useAvatar } from "../../common/Avatar/useAvatar";
import { Avatar } from "../../common/Avatar/Avatar";
import {FilledInput, InputLabel, Typography} from "@mui/material";
import {FormControl} from "../../common/TextField";
import { useSettingsBare } from "../../../toolympus/api/useSettings";
import { WASettings } from "../../settings/useSettingsEdit";
import { ReceiptLongOutlined } from "@mui/icons-material";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { RolesKeys } from "../../UserManagement/UserManagementContainer";
import { PseudoLink2 } from "../../../toolympus/components/primitives";
import { FileEdit } from "../../../toolympus/components/files/FileEdit";
import { useDownloadFile } from "../../../toolympus/hooks/useDownloadFile";
import { useActionWithConfirmation } from "../../../toolympus/api/useAction";
import { apiFetch } from "../../../toolympus/api/core";

interface Props {
    data: {
        profile: ProfileData;
        error: any;
        loading: boolean;
        save: (data: ProfileData) => void;
        reload: () => void;
    },
    userId: string;
    email: string;
}


export const MyProfilePage = (props: Props) => {
    const { userId, email} = props;
    const { profile, reload, loading, save} = props.data;
    const {input, checkbox, autocomplete, state} = useForm(profile);
    const avatar = useAvatar(userId);

    const settings = useSettingsBare<WASettings>("/api/settings", { is_applications_enabled: false, is_certificates_available: false });

    const { user } = useUser();
    const isConsentFormRequired = !!profile && (user?.roles || []).length === 1 && user?.roles[0] === RolesKeys.participant;
    const isConsentFormProvided = !!profile && !!profile.consent_form_id;

    const consentFormTemplate = useDownloadFile("/api/consent-form/my", `Winter Academy Consent Form ${state.firstname} ${state.lastname}.pdf`);
    const certificate = useDownloadFile(`/api/certificate/my`, `Winter Academy ${new Date().getFullYear()} certificate ${state.firstname} ${state.lastname}.pdf`);
    const resetConsentForm = useActionWithConfirmation(
      () => apiFetch<{}>("/api/consent-form/reset", "post").then(x => { reload(); return x; }),
      { title: "Reset consent form", confirmationHint: "You will need to upload an updated consent form." });

    if (loading) {
        return <LoadingIndicator />
    }

    return (
      <ColumnsContainer>
        <Avatar avatar={avatar} />

        <FormContainer>
            {isConsentFormRequired && !isConsentFormProvided && <>
              <Block desktop="7" laptop="12" phone="12">
                <Typography>Please download the {consentFormTemplate.isLoading && <LoadingIndicator sizeVariant="s" />}<PseudoLink2 border onClick={() => consentFormTemplate.download()}>consent form template</PseudoLink2>, sign, scan and upload it here</Typography>
              </Block>

              <Block desktop="5" laptop="12">
                <FileEdit
                  fileId={profile?.consent_form_id}
                  updateFileId={() => {}}
                  config={{
                    apiPath: "/api/consent-form",
                    onUpload: () => reload(),
                  }}
                  placeholder="Drop signed consent form here or click to upload"
                  />
              </Block>
            </>}


            {settings.is_certificates_available && !!profile?.attendance_status && <>
              <Block desktop="7" laptop="12" phone="12">
                <Typography>Your certificate of participation is available!</Typography>
              </Block>

              <Block desktop="5" laptop="12">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => certificate.download()}
                  startIcon={certificate.isLoading ? <LoadingIndicator sizeVariant="s" color="inherit" /> : <ReceiptLongOutlined />}>
                  Download
                </Button>
              </Block>
            </>}
            <Block desktop="2" laptop="4" phone="12">
                {autocomplete("title", ["Mr", "Ms", "Dr", "Prof"])}
            </Block>
            <Block desktop="5" laptop="8" phone="12">
                {input('firstname')}
            </Block>
            <Block desktop="5" laptop="12">
                {input('lastname')}
            </Block>
            <Block desktop="7" laptop="12">
                {input('position')}
            </Block>
            <Block desktop="5" laptop="12">
                {input('company', {label: "Organisation*"})}
            </Block>
            <Block desktop="12">
                {input('linkedin_url', {label: <>LinkedIn Page <i>(optionally)</i></>})}
            </Block>
            <Block desktop="12">
                {input('bio', {label: <>Bio <i>(optionally)</i></>, rows: 3})}
            </Block>
            <Block desktop="12">
                {input('interests', {label: <>Interestes <i>(optionally)</i></>})}
                {checkbox('consent_share_profile', {label: 'Hereby I consent the above data being shown to other participants and lecturers of the Winter Academy 2022.'})}
            </Block>
            
            <Block desktop="12">
                <FormControl variant="filled">
                    <InputLabel>E-mail</InputLabel>
                    <FilledInput
                        autoComplete="off"
                        value={email}
                        disabled={true}
                    />
                </FormControl>
                {checkbox('consent_email_org', {label: 'Hereby I consent my email address being used by the Russian Institute of Modern Arbitration to contact me and provide me with notifications and necessary information with regard to the Winter Academy 2022.'})}
                
                {!!profile?.consent_form_id &&
                  <Typography variant="caption" sx={{ marginLeft: "32px" }}>
                    You have provided the consent form. If you need to update it, please click <PseudoLink2 onClick={() => resetConsentForm.run()}>here</PseudoLink2>.
                  </Typography>}
            </Block>
            
            <Block desktop="4" laptop="7" phone="12">
                <Button onClick={() => save(state)} disabled={!state.consent_email_org} variant="contained" color="primary">Save changes</Button>
            </Block>
        </FormContainer>
      </ColumnsContainer>);
}
