import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../api/useFetch';
import { TutorEditPage } from './presentation/TutorEditPage';
import { User } from './types';
import { useTutorEdit } from './useTutorEdit';

export const TutorEditContainer = () => {
    const { id } = useParams<{id: string}>();
    const {data: users} = useFetch<User[]>([], {url: '/user'});
    const data = useTutorEdit(id);

    return <TutorEditPage data={data} users={users} id={id} />
}

