import { useEffect, useState } from "react";
import { useNewItem } from "../../toolympus/api/useNewItem";
import { Component, PageWithComponents, usePage } from "../../toolympus/components/Pages";
import { ValidationErrors } from "../../toolympus/components/schemed";
import { Schema } from "../../toolympus/hooks/useSchema";
import { useSchemaUISettings } from '../settings/useSchemaUISettings';

const formData = {
    birthdate: "",
    email: "",
    timezone: "",
    question2: "",
    lastname: "",
    phone: "",
    nationality: "",
    experience: "",
    wa_participation_year: "",
    pdc: "",
    wa_participation: "",
    question1: "",
    ml_document_id: "",
    preferred_name: "",
    title: "",
    middlename: "",
    firstname: "",
    question3: "",
    camera_acknowledged: "",
    cv_document_id: "",
    education: "",
    gender: "",
    country: "",
    avatar_id: null as string | null,
};

export type FormData = typeof formData;

export interface UseApplicationProps {
    schema: Schema;
    isSaved: boolean;
    send: () => Promise<null>;
    clear: () => void;
    isSaving: boolean;
    page: {
        page: PageWithComponents;
        isLoading: boolean;
    };
    componentsFilter: ({ subtype_text }: Component) => boolean;
    errors: ValidationErrors;
    data: FormData;
    update: (changes: Partial<FormData>) => void;
    validate: {
        question1: {
            words: number;
            valid: boolean;
        };
        question2: {
            words: number;
            valid: boolean;
        };
        question3: {
            words: number;
            valid: boolean;
        };
    }
}

const regFilter = /[^a-zA-Zа-яА-Я0-9]+/gi;
const calcWords = (text: string) => text.trim().replaceAll(regFilter, " ").split(" ").filter(s => !!s).length;

const LocalStorageKey = "__wa_application_form";

const loadFromLS = () => {
    let data = {};
    try {
        const storedStr = localStorage.getItem(LocalStorageKey);
        if(storedStr) {
            data = JSON.parse(storedStr);
        }
    } catch(e) {
    }
    return data;
}

const saveToLS = (data: Partial<FormData>) => {
    const copy = { ...data };
    delete copy.cv_document_id;
    delete copy.ml_document_id;
    localStorage.setItem(LocalStorageKey, JSON.stringify(copy));
}

export const useApplication = (): UseApplicationProps => {
    const { item, update, save, isLoading: isSaving, errors, startEditing } = useNewItem<FormData, null>(
        "/api/application",
        Object.keys(formData).reduce((obj, key) => ({...obj, [key]: ""}), {}) as FormData
    );

    const { schema, isSchemaNotAvailable } = useSchemaUISettings("/api/application/uiconfig");
    const [isSaved, setIsSaved] = useState(false);
    const page = usePage("/api/pages", 'application');

    const componentsFilter = ({subtype_text}: Component) => {
        return isSaved ? 
        subtype_text === "success" 
        : isSchemaNotAvailable ? subtype_text === "when-disabled" : subtype_text !== "success" && subtype_text !== "when-disabled"
    }


    const data = item || formData;

    
    const send = () => save().then(() => { setIsSaved(true); saveToLS({}); return null; });

    useEffect(() => {
        startEditing(loadFromLS());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(item) {
            saveToLS(item);
        }
    }, [item]);

    return { 
        schema, 
        isSaved, 
        data, 
        update, 
        clear: () => startEditing({}),
        send, 
        isSaving, 
        page, 
        componentsFilter,
        errors,
        validate: {
            question1: { words: calcWords(data.question1), valid: calcWords(data.question1) <= 500 },
            question2: { words: calcWords(data.question2), valid: calcWords(data.question2) <= 500 },
            question3: { words: calcWords(data.question3), valid: calcWords(data.question3) <= 500 },
        }
    };
}
