import {FetchTypes} from "../../toolympus/api/core";
import {useFetch} from "../../api/useFetch";
import {ProfileData} from "./types";

const entryProfile = () => ({
    _id: "",
    email: "",

    lastname: "",
    firstname: "",

    company: "",
    position: "",

    bio: "",
    
    consent_share_profile: false,
    consent_email_show: false,
    title: "",
    interests: "",
    linkedin_url: "",
    consent_email_org: false,
});

export const useProfile = () => {
    const { data: profile, loading, error, reload } = useFetch<ProfileData>(entryProfile(), {url: '/profile', method: FetchTypes.GET});
    
    const actionsFetch = useFetch(null, {url: '/profile'}, false);

    return { 
        profile,
        reload, 
        loading: loading || actionsFetch.loading,
        error,

        save: (data: ProfileData) => {
            actionsFetch
                .request({body: {...data, _id: undefined}, method: error? FetchTypes.POST : FetchTypes.PUT})
                .then(reload);
        }
    };
}
