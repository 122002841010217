import styled from '@emotion/styled'
import { BorderBox } from '../BorderBox';

export const AvatarContainer = styled.div`
  padding-top: 10px;
`;

export const HorizontalSpace = styled.div`
    height: 20px;
`;

export const Image = styled(BorderBox)`
    box-sizing: border-box;

    width: 100%;
    height: auto;

    margin-bottom: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        width: 100%;
        height: auto;
        filter: grayscale(1);
        aspect-ratio: 1;
    }
`;
Image.defaultProps = { all: true };

export const AvatarHint = styled.div`
    &::before {
        content: "*";
        color: red;
        margin-right: 3px;
    }

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: -0.24px;

    color: rgba(0, 0, 0, 0.6);

    padding: 15px 10px;
`;