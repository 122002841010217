import React from "react";
import styled from "@emotion/styled";
import { media } from "../frame";
import { InputLabel } from "@mui/material";
import { FormGrid } from "../../toolympus/components/primitives/Forms";

export const FormContainer = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const Section = styled.div`
    width: calc(100% - 3px);
    transition: 200ms;
    padding: 5px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    overflow-x: hidden;
    gap: 0.5rem;
`;
export const Title = styled.div`
    font-size: larger;
    font-weight: 500;
    padding-top: 50px;
    text-align: center;
`;

const Component = ({ className , children }: any) => {
    return <div className={className}>{children}</div>
}

export const Hint = styled(Component)`
    color: ${p => p.invalid ? "red" : "grey"};
`;

export const ApplicationContainer = styled.div`
    width: 100%;
    height: 100vh;
    
    display: grid;
    grid-template-rows: 1fr max-content;
    gap: 30px;

    padding: 0 15%;
    padding-bottom: 50px;

    box-sizing: border-box;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.87);

    & h1, h2, h3, h4, strong {
      font-weight: 500;
    }

    & a {
      text-decoration: none;
      padding-bottom: 2px;
      border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
      color: ${props => props.theme.palette.primary.main};
    }

    ${media.laptop} {
        padding: 0 30px;
        padding-bottom: 50px;
    }

    ${media.phone} {
        padding: 0 10px;
        padding-bottom: 50px;

    }
`;

export const StyledLink = styled.a`
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};
`;

export const PersonalInfoForm = styled(FormGrid)`
    align-items: start;

    ${props => props.theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr;
    }
`;

export const FileUploadLabel = styled(InputLabel)<{ hasError?: boolean }>`
    margin-bottom: 0.25rem;

    color: ${props => props.hasError ? props.theme.palette.error.main : "initial"};
`;

export const QuestionWrapper = styled.div`
    & > div:first-child > :last-child {
        margin-bottom: 0;
    }
`;

export const InfoPopover = styled.div`
    z-index: 2000;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    box-shadow: 2px -2px 10px -3px #33333380;
    border-radius: 8px;

    position: fixed;
    display: flex;
    flex-flow: column;
    align-items: start;
    padding: 1rem 1rem 0.5rem;
    gap: 0.5rem;
`;

export const InfoPopoverButtons = styled.div`
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: flex-end;
    gap: 1rem;
`;
