import React from "react"
import { useUser } from '../../toolympus/userContext/UserContext';
import { MyProfilePage } from "./presentation/MyProfilePage"
import { useProfile } from "./useProfile"

export const MyProfileContainer = () => {
    const {_id = "", email = ""} = useUser().user || {};

    return <MyProfilePage data={useProfile()} userId={_id} email={email}/>
}
