import React, { PropsWithChildren } from 'react';
import { CMSMenu } from './toolympus/components/CMS';
import { MediaLibProvider } from './toolympus/components/medialib';
import { PowerEditorBaseProps, PowerEditorBase } from './toolympus/components/PowerDoc';
import { ColumnsPlugin } from './toolympus/components/PowerDoc/plugins/Columns';
import { ExpanderPlugin } from './toolympus/components/PowerDoc/plugins/Expander';
import { useMediaFilesPlugin } from './toolympus/components/PowerDoc/plugins/MediaFiles';
import { TablesPlugin } from './toolympus/components/PowerDoc/plugins/Tables';
import { PowerEditorSettingsProvider } from './toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { TilesPlugin } from './toolympus/components/PowerDoc/plugins/Tiles';
import { CustomFieldsBlockPlugin } from './toolympus/components/PowerDoc/plugins/CustomFieldsBlock';

export const CmsMediaLibProvider = (props: PropsWithChildren<{ noLoad?: boolean }>) => {
  return <MediaLibProvider
    apiPath="/api/medialib"
    spaceId="documentation"
    getFilepath={mf => `/api/publicmedia/${mf.space_id}/${mf.filename}`}
    noLoad={props.noLoad}
    noReloadOnUpload={props.noLoad}
    >
    {props.children}
  </MediaLibProvider>

}

export const DocumentationEditor = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              ColumnsPlugin,
              TablesPlugin,
              ExpanderPlugin,
              mediaFilesPlugin,
              TilesPlugin,
              CustomFieldsBlockPlugin,
          ]}
          autoFocus={!props.viewMode}
          />
  );
}

export const DocumentationEditorWrapped = (props: PowerEditorBaseProps) => {
  return (
    <PowerEditorSettingsProvider EditorComponent={DocumentationEditor}>
      <DocumentationEditor
        {...props}
        />
    </PowerEditorSettingsProvider>
  );
}

export const CMS = () => {
  return (
    <CmsMediaLibProvider>
      <PowerEditorSettingsProvider EditorComponent={DocumentationEditor}>
        <CMSMenu
          apiPath="/api/cms"
          includeMediaLib
          />
      </PowerEditorSettingsProvider>
    </CmsMediaLibProvider>
  );
}


