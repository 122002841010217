import React, { useMemo, useState } from "react";
import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import { FileEdit } from "../../toolympus/components/files/FileEdit";
import { PageDisplay } from "../../toolympus/components/Pages";
import { FormControlsForFields } from "../../toolympus/components/schemed";
import { MarkdownDisplay } from "../../toolympus/components/schemed/Markdown";
import { BodyBg, MainBox } from "../Login/LoginForm.style";
import { Title, Section, FormContainer, Hint, ApplicationContainer, StyledLink, FileUploadLabel, QuestionWrapper, PersonalInfoForm, InfoPopover, InfoPopoverButtons } from "./ApplicationForm.style";
import { useApplication, UseApplicationProps } from "./useApplication";
import { FormGrid } from "../../toolympus/components/primitives/Forms";
import { LoadingIndicator } from "../../toolympus/components/primitives/LoadingIndicator";
import { useItemWithControls } from "../../toolympus/api/useWithControls";
import { createSelectSchema, mergeSchema } from "../../toolympus/hooks/useSchema";
import { PossiblyProtectedImg } from "../../toolympus/components/medialib";
import styled from '@emotion/styled';
import { Close } from "@mui/icons-material";

const AvatarWrapper = styled(FormGrid)<{ isError?: boolean }>`
  text-align: center;

  & .file-editor {
    border: 3px solid ${props => props.isError ? props.theme.palette.error.light : "transparent"};
    border-radius: 4px;
  }

  & > img {
    width: 100%;
    aspect-ratio: 1;
    border: 3px solid ${props => props.theme.palette.primary.main};
  }
`;
AvatarWrapper.defaultProps = { columns: "1fr" };

interface FormProps {
    data: UseApplicationProps;
    questionTexts: Record<string, string>;
    messages: Record<string, any>;
}

const PDCLabel = () => <span>
    According to <StyledLink href="https://pd.rkn.gov.ru/docs/Federal_Law_On_personal_data.doc" target="_blank" rel="noreferrer noopener">Federal Law of the Russian Federation dated 27.07.2006 No. 152-FZ "On Personal Data"</StyledLink>, I consent to processing of my personal data
    </span>;


const Form = (props: FormProps) => {
    const { 
        schema, 
        isSaved,
        data, 
        update, 
        clear,
        send, 
        isSaving,
        errors,
        validate
    } = props.data;
    const { questionTexts, messages } = props;

    const valid = Object.keys(validate).reduce((acc, key) => (validate as any)[key].valid && acc, true);

    const schemaFull = useMemo(() => {
      return mergeSchema(
        schema,
        { title: createSelectSchema(((messages.title_options || []) as string[]).map(o => ({ value: o, label: o }))) }
      )
    }, [schema, messages]);

    const { controls } = useItemWithControls({ data, update, errors }, { schema: schemaFull });

    const [isInfoPopoverOpen, setIsInfoPopoverOpen] = useState<boolean>(true);

    return <form>
            <FormContainer>

                    {isInfoPopoverOpen && <InfoPopover>
                        <Typography>The form is being auto-saved in your browser (except for the files), just in case. If you accidentally close the window, we will restore the data you entered. The data is not being sent anywhere, untill you submit the form though.</Typography>
                        <Typography>If you want to start from scratch, just clear the form.</Typography>

                        <InfoPopoverButtons>
                            <Button size="small" onClick={() => { clear(); setIsInfoPopoverOpen(false); }}>clear form</Button>
                            <Button size="small" color="primary" variant="contained" onClick={() => setIsInfoPopoverOpen(false)}>ok</Button>
                        </InfoPopoverButtons>
                    </InfoPopover>}

                    <Section>
                        <Title>Your Personal Details</Title>
                        <PersonalInfoForm key="names">
                            {controls([
                                    ["firstname"],
                                    ["lastname"],
                                    ["middlename"],
                                ])}
                        </PersonalInfoForm>

                        <PersonalInfoForm key="names-preferred" columns="1fr" noMargin>
                            {controls([
                                    ["preferred_name"],
                                ])}
                        </PersonalInfoForm>

                        <PersonalInfoForm key="info" noMargin>
                            {controls([
                                    ["title", { autoComplete: true }],
                                    ["gender"],
                                    ["birthdate"],
                                    ["nationality"],
                                    ["country"],
                                    ["timezone"],
                                ])}
                        </PersonalInfoForm>

                        <PersonalInfoForm key="contact" columns="1fr 1fr">
                            {controls([
                                    ["email"],
                                    ["phone"],
                                ])}
                        </PersonalInfoForm>
                    </Section>

                    <Section>
                      <Title>
                        Please upload your photo
                        {!!data.avatar_id && <IconButton size="small" onClick={() => update({ avatar_id: null })}>
                            <Close />
                          </IconButton>}
                      </Title>

                      <FormGrid columns="repeat(3,1fr)" forceEvenColumns>
                          <div />
                          <AvatarWrapper noMargin isError={errors.fieldHasErrors("avatar_id")}>
                            <Typography variant="caption" color="textSecondary">500x500 pixels or a larger square image would work best</Typography>
                            {data.avatar_id
                              ? <PossiblyProtectedImg protected src={`/api/application/avatar/${data.avatar_id}`} />
                              : <FileEdit
                                  fileId={data.avatar_id}
                                  updateFileId={v => update({ avatar_id: v })}
                                  config={{
                                    apiPath: "/api/application/avatar",
                                  }}
                                />}
                          </AvatarWrapper>
                        </FormGrid>
                    </Section>


                    <Section>
                        <Title>Your Education and Experience</Title>

                        <FormGrid columns="1fr">
                            {controls([
                              ["education", { autoRows: true }],
                              ["experience", { autoRows: true }],
                              ["how_did_you_learn", { autoRows: true }],
                            ])}

                            <FormControl error={errors.fieldHasErrors("wa_participation")}>
                                <FormLabel>Have you participated in our Winter Academy before?*</FormLabel>
                                <RadioGroup row value={data.wa_participation} onChange={(_, value) => update({ wa_participation: value })}>
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>

                            {controls([
                                    ["wa_participation_year", { visibleIf: (row) => row.wa_participation === "yes", label: "Please indicate the year (e.g. 2021)"}],
                                ])}
                            
                            <div key="cv_document_id">
                                <FileUploadLabel hasError={errors.fieldHasErrors("cv_document_id")}>Please upload your CV in English *</FileUploadLabel>
                                <FileEdit 
                                    preventDownload
                                    fileId={data.cv_document_id} 
                                    updateFileId={id => update({ cv_document_id: id || "" })} 
                                    config={{ apiPath:"/api/application/document", dontRemoveFile: true }}
                                />
                                <FormHelperText>(PDF format only)</FormHelperText>
                            </div>
                        </FormGrid>
                    </Section> 
                    <Section>
                        <Title>Your Motivation</Title>

                        <FormGrid columns="1fr">
                            <div>
                                <FileUploadLabel hasError={errors.fieldHasErrors("ml_document_id")}>Please upload your motivation letter in English *</FileUploadLabel>
                                <FileEdit 
                                    preventDownload
                                    fileId={data.ml_document_id} 
                                    updateFileId={id => update({ ml_document_id: id || "" })} 
                                    config={{ apiPath:"/api/application/document", dontRemoveFile: true }}
                                />
                                <FormHelperText>(PDF format only)</FormHelperText>
                            </div>
                        </FormGrid>
                    </Section>

                    <Section key="questions">
                        <Title>ADR Knowledge Valuation Test</Title>

                        <FormGrid columns="1fr">
                            <QuestionWrapper key="question1">
                                <MarkdownDisplay text={questionTexts?.question1} />
                                <FormControlsForFields
                                    errors={errors} 
                                    data={data}
                                    schema={schema}
                                    fields={[
                                        ["question1", {
                                            label: " ",
                                            autoRows: true,
                                            hint: <Hint invalid={!validate.question1.valid}>{validate.question1.words} / 500 words</Hint>,
                                        }],
                                    ]}
                                    onChange={(_, changes) => update(changes)}
                                />
                            </QuestionWrapper>

                            <QuestionWrapper key="question2">
                                <MarkdownDisplay text={questionTexts?.question2} />
                                <FormControlsForFields
                                    errors={errors} 
                                    data={data}
                                    schema={schema}
                                    fields={[
                                        ["question2", {
                                            label: " ",
                                            autoRows: true,
                                            hint: <Hint invalid={!validate.question2.valid}>{validate.question2.words} / 500 words</Hint>,
                                         }],
                                    ]}
                                    onChange={(_, changes) => update(changes)}
                                />
                            </QuestionWrapper>

                            <QuestionWrapper key="question3">
                                <MarkdownDisplay text={questionTexts?.question3} />
                                <FormControlsForFields
                                    errors={errors} 
                                    data={data}
                                    schema={schema}
                                    fields={[
                                        ["question3", {
                                            label: " ",
                                            autoRows: true,
                                            hint: <Hint invalid={!validate.question3.valid}>{validate.question3.words} / 500 words</Hint>,
                                        }],
                                    ]}
                                    onChange={(_, changes) => update(changes)}
                                />

                            </QuestionWrapper>

                            {controls([
                                    ["camera_acknowledged", { label: "Please note that all participants of the Winter Academy would need to turn on their cameras during the lectures" }],
                                    ["pdc", { label:  <PDCLabel /> }]
                                ])}
                        
                            {!isSaved && valid && 
                                <Button 
                                    color="primary" 
                                    variant="contained"
                                    disabled={isSaving}
                                    onClick={() => send()}
                                    style={{ justifySelf: "center", minWidth: "200px" }}
                                >
                                    {isSaving ? <LoadingIndicator sizeVariant="font" /> : "Submit"}
                                </Button>
                            }
                        </FormGrid>
                    </Section>

            </FormContainer>
        </form>;
}

export const ApplicationForm = () => {
    const formData = useApplication();
    const  { 
        schema, 
        page, 
        componentsFilter,
    } = formData;


    const [questionTexts,messages] = useMemo(() => {
      const questionTexts = page.page.components.reduce((r,component) => ({ ...r, [component.subtype_text]: component.content_text }), {});
      const messagesJson = page.page.components.find(c => c.subtype_text === "settings")?.content_text;
      const messages: Record<string, any> = messagesJson ? JSON.parse(messagesJson) : {};

      return [
        questionTexts,
        messages,
      ]
    }, [page.page.components]);

    return <ApplicationContainer>
        <BodyBg />

        <MainBox>
            {schema && <>
                <PageDisplay 
                    data={{
                        ...page, 
                        page: { 
                            ...page.page, 
                            components: page.page.components.filter(componentsFilter) 
                        }
                    }}
                    customComponents={{
                        success: ({ content_text }) => <div>
                            <MarkdownDisplay text={content_text} />
                        </div>,
                        "when-disabled": ({ content_text }) => <div>
                            <MarkdownDisplay text={content_text} />
                        </div>,
                        form: () => <Form data={formData} questionTexts={questionTexts} messages={messages} />,
                    }}
                />
            </>}
        </MainBox>

    </ApplicationContainer>
}