import { useCrudItem } from '../../toolympus/api/useSimpleCrud';
import { LectureFieldProps } from './SettingsPage';

export interface WASettings {
  is_applications_enabled: boolean | string;
  is_certificates_available: boolean;
}

export const useSettingsEdit = () => {
    const settings = useCrudItem<WASettings>("/api/settings", {
        defaultValue: { is_applications_enabled: false, is_certificates_available: false },
    });

    const lectureRoom = useCrudItem<LectureFieldProps | LectureFieldProps & { token: string }>("/api/lectureroom", {
        defaultValue: { token_secret: "", available: false, domain: "", room: "" }
    });

    return {
        lectureRoom,
        settings,
        isLoading: settings.isLoading || lectureRoom.isLoading,
        isShowSaveButton: lectureRoom.hasChanges || settings.hasChanges
    }
}